import { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../../context/auth/AuthContext";
import { Access } from "../../Helpers/AuthHelper";

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [form, setForm] = useState({
    email: "",
    password: "",
    viewPassowrd: false
  });

  let user = useContext(authContext); 
  const usuario = user.user;
  

  const onClick = (e) => {
    switch (e.target.name) {
      case "viewPassword":{
        e.preventDefault();
        if(form.viewPassowrd){
          setForm({
            ...form,
            viewPassowrd: false
          })
        } else {
          setForm({
            ...form,
            viewPassowrd: true
          })
        }
      }  
      break;
    }
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case "password":
      case "email":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }
      break;
    }
  }

  const onAccess = async (e) => {
    e.preventDefault()

    if(
      form.email.trim() == "" ||
      form.password.trim() == ""
    ){
      return setError("Los campos de encuentran vacíos.");
    }

      const response = await Access(form, setLoading);
  }

  useEffect(() => {
    if(usuario.auth){
      return navigate("/app/box");
    }
  },[])
  return (
    <div className="container-md bg-light p-5" style={{borderRadius: "0px 0px 30px 30px"}}>
      <div className="w-100 d-flex justify-content-center mb-5 mt-5">
        <img src="/assets/img/marca/antesala-logo.svg" width={"150PX"} className="img-fluid" alt="" />
      </div>
      <form action="" className="texto-normal">
        <div className="mb-3 text-bold fs-6">
          Ingresa a tu cuenta
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Email
          </label>
          <input
            name="email"
            onChange={(event) => onChange(event)}
            type="text"
            class="form-control rounded-10 border-custom-naranja"
            placeholder="Ingresa tu usuario..."
          />
          {
            error
            ? form.email.trim() == ""
            ? <span className="text-danger">Por favor ingrese su email.</span>
            : <span className="text-info fs-7">Completado! <i class="bi bi-check-all"></i></span>
            : ""
          }
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Contraseña
          </label>
          <div class="input-group mb-1">
            <label className="input-group-text bg-white border-custom-naranja text-custom-naranja">
              {
                form.viewPassowrd
                ? <i class="bi bi-eye"></i>
                : <i class="bi bi-eye-slash"></i>
              }
              <button className="d-none" name="viewPassword" onClick={(event) => onClick(event)}></button>
            </label>
            <input
              name="password"
              onChange={(event) => onChange(event)}
              type={form.viewPassowrd? "text" : "password"}
              class="form-control border-custom-naranja"
              placeholder="Ingresa tu contraseña."
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>  
          {
            error
            ? form.email.trim() == ""
            ? <span className="text-danger d-block">Por favor ingrese su password.</span>
            : <span className="text-info fs-7 d-block">Completado! <i class="bi bi-check-all"></i></span>
            : ""
          }
        </div> 
        <div className="mb-3">
          <button 
          name="access"
          onClick={(event) => onAccess(event)}
          className="w-100 btn btn-custom-naranja text-white border-0 GothamBold" 
          disabled={isLoading}
          >
            {
              isLoading
              ? <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              : "Ingresar"
            }
          </button>
        </div>
      </form>
      
     
    </div>
  );
};

export default Login;
