const Footer = () => {


  return (
    <div className="container-fluid bg-dark m-0 w-100 h-100 position-fixed d-flex justify-content-center" >
      <div className="text-white">© Copyright - 2022 | Antesala.</div>
    </div>
  )
}

export default Footer