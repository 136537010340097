import { useEffect } from "react";
import { useState } from "react";
import { ActualizarReporte } from "../../../helpers/ReporteHelper";
import ApiRest from "../../../hooks/useRest";
import useApi from "../../Hooks/useApi";
import QrReader from "modern-react-qr-reader";
import AlertSuccess from "../../../Alerts/AlertSucces";
import Swal from "sweetalert2";
import clientAxios from "../../../helpers/Config";

const Tickets = ({ evento, setLabel }) => {
  const [scanner, setScanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scanned, setScanned] = useState(null);
  const [steps, setSteps] = useState(0);
  const [mode, setMode] = useState("environment")
  //parametros
  const [params, setParams] = useState({
    id: String(evento.id),
    search: "",
    status: "",
    date_range: [],
  });
  //hook de consulta
  const { data, messagem, isLoading } = useApi(
    "api/auth/Boleteria/Eventos",
    params,
    steps
  );

  const previewStyle = {
    height: 240,
    width: 320,
    marginBottom: 90,
  };
  return (
    <div
      className="container-md bg-light pb-5 pt-2"
      style={{ borderRadius: "0px 0px 30px 30px" }}
    >

    <div class="mb-3">
        <label for="exampleFormControlInput1" class="w-100 text-center btn btn-custom-naranja text-white text-dark fs-7">
          Volver a seleccionar evento.
        <button className="d-none"
        onClick={(event) => setLabel("cual")}>
        </button>
        </label>
      </div>

      <div class="mb-3 d-none">
        <label for="exampleFormControlInput1" class="form-label text-dark fs-7">
          Busca la entrada según el nombre del usuario.
        </label>
        <input
          type="email"
          class="form-control rounded-pill fs-7"
          id="exampleFormControlInput1"
          placeholder="..."
        />
      </div>

      <div className="w-100 mb-3">
        {scanner ? (
          <label className="btn btn-danger w-100">
            Parar QR
            <button
              className="d-none"
              onClick={(event) => setScanner(false)}
            ></button>
          </label>
        ) : (
          <label className="btn btn-primary w-100">
            Escanear QR
            <button
              className="d-none"
              onClick={(event) => setScanner(true)}
            ></button>
          </label>
        )}
        <div className="d-flex justify-content-center">
          {scanner ? (
            <QrReader
              delay={100}
              style={previewStyle}
              facingMode={mode}
              onError={""}
              onScan={async (result) => {
                setScanned(result);
                if (result) {
                  let formData = new FormData();
                  formData.append("code", String(result));

                  const response = await clientAxios({
                    method: "post",
                    url: "api/auth/Boleteria/Actualizar",
                    data: formData,
                  })
                    .then((result) => {
                      return result.data;
                    })
                    .catch((result) => {
                      return result.data;
                    });

                  switch (response.status) {
                    case 0:
                      {
                        /* Entrada no existe */
                        Swal.fire({
                          position: "center",
                          icon: "warning",
                          title: "La entrada no existe",
                          showConfirmButton: false,
                        });
                      }
                      break;
                    case 1:
                      {
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: "Ingreso de entrada con codigo " + result,
                          html:
                            "<ul class='list-group'>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cliente:</div> "+response.data.email_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Telefono:</div> "+response.data.telefono_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>DNI:</div> "+response.data.dni_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Fecha de evento:</div> "+response.data.fecha+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cantidad de entradas:</div> "+response.data.cantidad+" </li>" +
                            "</ul>",
                          showConfirmButton: false,
                        });
                      }
                      break;
                    case 2:
                      {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Entrada en reembolso " + result,
                          html:
                            "<ul class='list-group'>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cliente:</div> "+response.data.email_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Telefono:</div> "+response.data.telefono_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>DNI:</div> "+response.data.dni_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Fecha de evento:</div> "+response.data.fecha+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cantidad de entradas:</div> "+response.data.cantidad+" </li>" +
                            "</ul>",
                          showConfirmButton: false,
                        });
                      }
                      break;
                    case 3:
                      {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Entrada inactiva " + result,
                          html:
                            "<ul class='list-group'>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cliente:</div> "+response.data.email_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Telefono:</div> "+response.data.telefono_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>DNI:</div> "+response.data.dni_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Fecha de evento:</div> "+response.data.fecha+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cantidad de entradas:</div> "+response.data.cantidad+" </li>" +
                            "</ul>",
                          showConfirmButton: false,
                        });
                      }
                      break;
                    case 4:
                      {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Entrada rechazada " + result,
                          html:
                            "<ul class='list-group'>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cliente:</div> "+response.data.email_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Telefono:</div> "+response.data.telefono_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>DNI:</div> "+response.data.dni_cliente+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Fecha de evento:</div> "+response.data.fecha+" </li>" +
                            "<li class='list-group-item border-0 text-nowrap'><div class='fw-bold'>Cantidad de entradas:</div> "+response.data.cantidad+" </li>" +
                            "</ul>",
                          showConfirmButton: false,
                        });
                      }
                      break;
                  }

                  return setSteps(steps + 1);
                }
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="d-flex justify-content-between">
          <label className="btn btn-primary w-100 me-2">
            Camara delantera
            <button className="d-none" onClick={(event) => setMode("user")}></button>
          </label>
          <label className="btn btn-primary w-100">
            Camara trasera
            <button className="d-none" onClick={(event) => setMode("environment")}></button>
          </label>
        </div>
      </div>

      <div>
        <ul class="list-group  fs-7">
          {data ? (
            data.reportes.map((reporte, index) => (
              <li class="list-group-item border-0 p-1 m-0 d-flex justify-content-between">
                <div className="w-100">
                  <div
                    class="accordion accordion-flush w-100"
                    id={"flush" + index}
                  >
                    <div class="accordion-item w-100 text-dark">
                      <div class="accordion-header" id={"heading" + index}>
                        <button
                          class={reporte.registro_cliente == "CONCURRIO"? "btn btn-success collapsed text-dark text-start w-100 fs-7 d-flex justify-content-between text-white" : "btn btn-outline-dark collapsed text-dark text-start w-100 fs-7 d-flex justify-content-between"}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#accordion" + index}
                          aria-expanded="false"
                          aria-controls={"accordion" + index}
                        >
                          <div>
                            <span className="fw-normal ">
                              {" "}
                              {reporte.nombre_cliente}
                            </span>
                            <span className=" d-block">
                              Cantidad: {reporte.cantidad}
                            </span>
                            <span className="">Codigo: {reporte.codigo}</span>
                          </div>
                          <div className="">{reporte.registro_cliente}</div>
                        </button>
                      </div>
                      <div
                        id={"accordion" + index}
                        class="accordion-collapse collapse"
                        aria-labelledby={"heading" + index}
                        data-bs-parent={"#flush" + index}
                      >
                        <div class="accordion-body">
                          <ul class="list-group">
                            <li class="list-group-item border-0 p-0 d-flex justify-content-between">
                              <div>Email: </div>
                              <div>{reporte.email_cliente}</div>
                            </li>
                            <li class="list-group-item border-0 p-0 d-flex justify-content-between">
                              <div>Telefono: </div>
                              <div>{reporte.telefono_cliente}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </li>
            ))
          ) : (
            <div>Loading...</div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Tickets;
