import { useState } from "react";
import { totalReportEvents } from "../../../Functions/FunctionReportes";
import { ConcatDate } from "../../Functions/ConcatDate";
import { Tickets } from "../../Functions/Tickets";
import useApi from "../../Hooks/useApi";

const Which = ({ setLabel, onClick }) => {
  const hoy = new Date();

  
  const [steps, setSteps] = useState(0)
  //parametros get
  const [params, setParams] = useState({
    id: "",
    search: "",
    status: "",
    date_range: [
      hoy.getDate() + " " + (hoy.getMonth() + 1),
      hoy.getDate() + " " + (hoy.getMonth() + 1),
    ],
  });
  //hook de consulta
  const { data, messagem, isLoading } = useApi(
    "api/auth/Boleteria/Eventos",
    params,
    steps
  );

  return (
    <div
      className="container-md bg-light p-5"
      style={{ borderRadius: "0px 0px 30px 30px" }}
    >
      <div className="row">
        <div className="col-md-12 my-3 text-center">
          <h1 className="fs-3 m-0">Selecciona tu evento</h1>
          <span className="fs-7">
            Selecciona el evento que se realizara hoy.
          </span>
        </div>

        {data ? (
          data.map((evento) => (
            <div className="col-md-12 p-1 m-0 mb-3">
              <ul className="list-group">
                <li className="list-group-item border-0 m-0 p-0">
                  <div className="overflow-hidden" style={{ height: "150px" }}>
                    <img
                      src={"./assets/eventos/" + evento.imagen_evento}
                      width="100%"
                      className=""
                      alt=""
                    />
                  </div>
                </li>
                <li className="list-group-item border-0">{evento.nombre}</li>
                <li className="list-group-item border-0 fs-7">
                  {ConcatDate(evento.fechas, "funciones")}
                </li>
                <li className="list-group-item border-0">
                  {evento.categorias.map((categoria) => (
                    <span className="btn btn-dark p-0 px-2 mx-1 rounded-1 fs-10">
                      {categoria.nombre}
                    </span>
                  ))}
                </li>
                <li className="list-group-item border-0 d-flex justify-content-between">
                  <div className="">
                    <span className="fs-7">Entradas totales</span>
                    <span className="d-block">{Tickets(evento, "tickets")}</span>
                  </div>
                  <div className="">
                    <span className="fs-7">Ventas totales</span>
                    <span className="d-block">${totalReportEvents(evento.reportes).total_pagos}</span>
                  </div>
                </li>
                <li className="list-group-item border-0 d-flex justify-content-between">
                  <button 
                  className="btn btn-custom-naranja w-100 text-white fs-7"
                  name="acceder"
                  onClick={(event) => onClick(event, evento)}
                  >
                    Acceder
                  </button>
                </li>
              </ul>
            </div>
          ))
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
};

export default Which;
