import { useState } from "react";
import AlertSuccess from "../../Alerts/AlertSucces";
import clientAxios from "../../helpers/Config";

export const Access = async (form, setLoading) => {
  setLoading(true);

  let message = "";
  
  let formData = new FormData();
  formData.append("email", form.email)
  formData.append("password", form.password); 
  
  const response = await clientAxios({
  method: 'post',
  url: '/api/Login',
  data: formData
  }).then(async (result) => {
      setLoading(false);
      localStorage.setItem('token', result.data.token);
      message = result.data.msg;
      return window.location.href = '/app/box'; 
    }).catch((result) => {
      setLoading(false);
      message = result.data.response.msg;
    });
  
  return {
    message,
  }
}


export const Exit = async () => {

  const response = await clientAxios.get('/api/auth/Logout');
  return AlertSuccess("Cerrando sesion..."), sessionStorage.clear(), window.location.href = '/APP/login'; 
  
}