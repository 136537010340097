import { useContext } from "react";
import { useState } from "react"
import { Navigate } from "react-router-dom";
import { authContext } from "../../../context/auth/AuthContext";
import Tickets from "./Tickets";
import Which from "./Which";

const Box = () => {
  let user = useContext(authContext); 
  const usuario = user.user;

  //vista por defecto "seleccionar mis eventos"
  const [label, setLabel] = useState("cual");
  const [select, setSelect] = useState(null);

  //eventos
  const onChange = (e) => {

  }

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "acceder":{
        setSelect(value);
        setLabel("entradas");
      }
      break;
    }
  }

  
  //auth
  if(usuario.data == null){
    return <Navigate to="/app/login" />  
  }


  switch (label) {
    case "cual":{
      return <Which  setLabel={setLabel} onClick={onClick} />
    }
    break;
    case "entradas":{
      return <Tickets setLabel={setLabel}  onClick={onClick} evento={select} />
    }
    break;
  }

  return "";
}

export default Box