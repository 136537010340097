import { useContext } from "react";
import { authContext } from "../../../context/auth/AuthContext";
import { cerrarSession } from "../../../helpers/AccessHelper";
import { Exit } from "../../Helpers/AuthHelper";

const Header = () => {
  let user = useContext(authContext);
  const usuario = user.user.data;

  return (
    <div className="container-fuild bg-light h-100">
      <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
          <a class="navbar-brand fw-bold" href="#">
            Boleteria
          </a>
          <label className="btn p-0">
            Cerrar sesion <i class="bi bi-power"></i>
            <button
              className="d-none"
              name="cerrarSesion"
              onClick={(event) => Exit()}
            ></button>
          </label>
          <button
            class="navbar-toggler d-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            {usuario ? (
              <ul class="navbar-nav">
                <li class="nav-item">
                  {usuario.nombre + " " + usuario.apellido}
                </li>
                <li class="nav-item">
                  <label className="btn p-0">
                    Cerrar sesion <i class="bi bi-power"></i>
                    <button
                      className="d-none"
                      name="cerrarSesion"
                      onClick={(event) => Exit()}
                    ></button>
                  </label>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
