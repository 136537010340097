import { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { authContext } from "../../context/auth/AuthContext";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Apk = ({ children }) => {
  //initialState
  const [screen, setScreen] = useState(null);

  const readScreen = () => {
    let width = window.screen.width
    setScreen(width);
  }

  useEffect(() => {
    readScreen()
  },[])
  return <>
  {
    screen > 0 && screen < 750 
    ? 
    <div className="bg-dark">
      <Header />
      <main className="m-0">{children}</main>
      <Footer />
    </div>
    : <div className="w-100 p-3">
      <h1>No se permite esta sección en resoluciones mayores a dispositivos mobiles.</h1>
    </div>
  }
  </>
  
};

export default Apk;
